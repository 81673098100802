*,
*::before,
*::after {
    box-sizing: inherit;
    font-family: inherit;
    margin: 0;
    padding: 0;
}

html,
body {
    height: 100%;
    box-sizing: border-box;
    overflow-x: hidden;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

img {
    display: block;
    max-width: 100%;
}

.container {
    max-width: 85vw;
    width: min(90vw, 85rem);
    margin-inline: auto;
    text-align: center;
}

header {
    margin-top: 3rem;
    margin-bottom: 3rem;
}

ul {
    list-style: none;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 3rem;
    margin-bottom: 3rem;
}

@media (max-width: 1200px) {
    ul {
        grid-gap: 1rem;
    }
}

@media (max-width: 1176px) {
    ul {
        grid-template-columns: repeat(auto-fit, minmax(245px, 1fr));
    }
}

li a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

li img {
    height: 400px;
    width: 300px;
    object-fit: cover;
    border-radius: 11px;
}

li button {
    background-color: #6C110E;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    font-weight: bold;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
}

li a {
    color: #000;
    text-decoration: none;
}

.product-detail {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
}

.product-detail img {
    max-height: 600px;
}

.loaderText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    color: #F85902
}

h1{
    color:  #F85902
}
